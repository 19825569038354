var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"displaySurveyList"}},[(_vm.$route.path === '/tool/naire')?[_c('div',{staticClass:"topinfo"},[_vm._v(" 信息采集，可自定义采集学员信息，包括手机号、微信号、姓名等联系方式以及个性化用户信息，可同步至用户资料；还可以运用采集表单，创建题目进行开始考试或打卡。 ")]),_c('div',{staticClass:"all"},[_c('div',{staticClass:"table-contain"},[_c('pagination2',{ref:"childDialogBox",attrs:{"option":_vm.post,"url":"/PcSurvey/getSurveyList"},on:{"complete":_vm.complete},scopedSlots:_vm._u([{key:"default",fn:function({ tableData }){return [_c('div',{staticStyle:{"overflow":"hidden","min-width":"828px"}},[_c('div',{staticClass:"zi1",on:{"click":function($event){return _vm.$router.push({
                    path: '/tool/naire/newlyBuild',
                  })}}},[_vm._v(" 新建信息采集 ")]),_c('div',{staticStyle:{"color":"red","font-size":"12px","margin-left":"20px","float":"left","margin-top":"13px"}},[_vm._v(" 暂不支持小程序和PC网校 ")]),_c('div',{staticClass:"head-right"},[_c('div',{staticClass:"list2"},[_c('el-select',{model:{value:(_vm.post.type),callback:function ($$v) {_vm.$set(_vm.post, "type", $$v)},expression:"post.type"}},_vm._l((_vm.options2),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('el-select',{model:{value:(_vm.post.naire_status),callback:function ($$v) {_vm.$set(_vm.post, "naire_status", $$v)},expression:"post.naire_status"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('el-input',{staticStyle:{"border":"0px"},attrs:{"placeholder":"输入名称搜索"},model:{value:(_vm.post.naire_name),callback:function ($$v) {_vm.$set(_vm.post, "naire_name", $$v)},expression:"post.naire_name"}})],1)])]),_c('div',{staticClass:"clear"}),_c('el-table',{attrs:{"data":tableData,"sortable":"custom","header-cell-style":{
                background: 'rgba(245,245,245,1)',
                color: '#333333',
              }}},[_c('el-table-column',{attrs:{"prop":"naire_name","label":"名称"}}),_c('el-table-column',{attrs:{"prop":"naire_name","label":"采集概况"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('p',[_vm._v("总数："+_vm._s(row.all_number))]),_c('p',[_vm._v("今日："+_vm._s(row.today_number))])]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"naire_name","label":"采集方式"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm._f("getmodefl")(row.type))+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"label":"触发行为"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(row.type == 1)?_c('div',[(row.naire_position == 1)?_c('div',[_vm._v("登录后")]):(row.naire_position == 2)?_c('div',[_vm._v("报名时")]):(row.naire_position == 3)?_c('div',[_vm._v("兑换课程时")]):(row.naire_position == 4)?_c('div',[_vm._v("购买会员时")]):(row.naire_position == 5)?_c('div',[_vm._v("报名后")]):_vm._e()]):_c('div',[_vm._v("-")])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(row.naire_status == 1)?_c('div',{staticClass:"bluecolor"},[_vm._v(" 已启用 ")]):_vm._e(),(row.naire_status == 0)?_c('div',{staticClass:"redcolor"},[_vm._v(" 已停用 ")]):_vm._e()]}}],null,true)}),_c('el-table-column',{attrs:{"label":"操作","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function({ row, $index }){return [_c('div',{staticClass:"btns"},[_c('span',{staticClass:"text",on:{"click":function($event){return _vm.$router.push({
                          path: '/tool/naire/completedCadets',
                          query: { naire_id: row.naire_id },
                        })}}},[_vm._v(" 详情 ")]),_c('span',{staticClass:"space"}),(row.all_number == 0)?_c('span',{staticClass:"text",on:{"click":function($event){return _vm.$router.push({
                          path: '/tool/naire/newlyBuild1',
                          query: { naire_id: row.naire_id },
                        })}}},[_vm._v(" 编辑 ")]):_vm._e(),(row.all_number > 0)?_c('span',{staticClass:"text",on:{"click":function($event){return _vm.edit(row.naire_id, row.naire_id)}}},[_vm._v(" 编辑 ")]):_vm._e(),_c('span',{staticClass:"space"}),(row.type == 2)?_c('span',{staticClass:"text",on:{"click":function($event){return _vm.share(row)}}},[_vm._v("分享")]):_vm._e(),(row.type == 2)?_c('span',{staticClass:"space"}):_vm._e(),_c('el-dropdown',[_c('span',{staticClass:"text"},[_vm._v("更多")]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{nativeOn:{"click":function($event){return _vm.exportTable(row.naire_id, row.all_number)}}},[_vm._v(" 导出 ")]),(row.naire_status == 1)?_c('el-dropdown-item',{nativeOn:{"click":function($event){return _vm.stopt(row.$index, row.naire_id)}}},[_vm._v(" 停用 ")]):_vm._e(),(
                            row.naire_status == 0 && row.naire_position == 1
                          )?_c('el-dropdown-item',{nativeOn:{"click":function($event){return _vm.open2(row.$index, row.naire_id)}}},[_vm._v(" 启用 ")]):(
                            row.naire_status == 0 &&
                            Number(row.naire_position) > 1
                          )?_c('el-dropdown-item',{nativeOn:{"click":function($event){return _vm.open(row.$index, row.naire_id)}}},[_vm._v(" 启用 ")]):_vm._e(),_c('el-dropdown-item',{nativeOn:{"click":function($event){return _vm.del($index, row.naire_id)}}},[_vm._v(" 删除 ")])],1)],1)],1)]}}],null,true)})],1)]}}],null,false,3351510934)})],1)]),_c('el-dialog',{attrs:{"title":"分享","visible":_vm.dialogVisible,"width":"422px","close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('span',{staticStyle:{"line-height":"22px"}},[_vm._v(" 《"+_vm._s(_vm.selectRow.naire_name)+"》"+_vm._s(_vm.selectRow.description)+"，请使用手机微信打开 "+_vm._s(_vm.selectRow.url)+" ")]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticStyle:{"width":"116px"},attrs:{"size":"medium"},on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v(" 关闭 ")]),_c('el-button',{staticClass:"cp for-copy",attrs:{"type":"primary","size":"medium","data-clipboard-text":`《${_vm.selectRow.naire_name}》${_vm.selectRow.description}，请使用手机微信打开  ${_vm.selectRow.url}`}},[_vm._v(" 复制文案+链接 ")])],1)])]:_vm._e(),_c('router-view')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }