<template>
  <div id="displaySurveyList">
    <template v-if="$route.path === '/tool/naire'">
      <div class="topinfo">
        信息采集，可自定义采集学员信息，包括手机号、微信号、姓名等联系方式以及个性化用户信息，可同步至用户资料；还可以运用采集表单，创建题目进行开始考试或打卡。
      </div>

      <div class="all">
        <div class="table-contain">
          <pagination2
            :option="post"
            url="/PcSurvey/getSurveyList"
            ref="childDialogBox"
            @complete="complete"
          >
            <template v-slot:default="{ tableData }">
              <div style="overflow: hidden; min-width: 828px">
                <div
                  class="zi1"
                  @click="
                    $router.push({
                      path: '/tool/naire/newlyBuild',
                    })
                  "
                >
                  新建信息采集
                </div>
                <div
                  style="
                    color: red;
                    font-size: 12px;
                    margin-left: 20px;
                    float: left;
                    margin-top: 13px;
                  "
                >
                  暂不支持小程序和PC网校
                </div>

                <div class="head-right">
                  <div class="list2">
                    <el-select v-model="post.type">
                      <el-option
                        v-for="item in options2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>

                    <el-select v-model="post.naire_status">
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>

                    <el-input
                      v-model="post.naire_name"
                      placeholder="输入名称搜索"
                      style="border: 0px"
                    ></el-input>
                  </div>
                </div>
              </div>

              <div class="clear"></div>

              <el-table
                :data="tableData"
                sortable="custom"
                :header-cell-style="{
                  background: 'rgba(245,245,245,1)',
                  color: '#333333',
                }"
              >
                <el-table-column
                  prop="naire_name"
                  label="名称"
                ></el-table-column>

                <el-table-column prop="naire_name" label="采集概况">
                  <template slot-scope="{ row }">
                    <p>总数：{{ row.all_number }}</p>
                    <p>今日：{{ row.today_number }}</p>
                  </template>
                </el-table-column>

                <el-table-column prop="naire_name" label="采集方式">
                  <template slot-scope="{ row }">
                    {{ row.type | getmodefl }}
                  </template>
                </el-table-column>

                <!-- 1.4 弹出位置：登录后、报名前、学习前三个 -->
                <el-table-column label="触发行为">
                  <template slot-scope="{ row }">
                    <div v-if="row.type == 1">
                      <div v-if="row.naire_position == 1">登录后</div>
                      <div v-else-if="row.naire_position == 2">报名时</div>
                      <div v-else-if="row.naire_position == 3">兑换课程时</div>
                      <div v-else-if="row.naire_position == 4">购买会员时</div>
                      <div v-else-if="row.naire_position == 5">报名后</div>
                    </div>
                    <div v-else>-</div>
                  </template>
                </el-table-column>

                <el-table-column label="状态">
                  <template slot-scope="{ row }">
                    <!-- 全部、已启用、已停用 -->
                    <div v-if="row.naire_status == 1" class="bluecolor">
                      已启用
                    </div>
                    <div v-if="row.naire_status == 0" class="redcolor">
                      已停用
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="操作" width="200">
                  <template slot-scope="{ row, $index }">
                    <div class="btns">
                      <span
                        class="text"
                        @click="
                          $router.push({
                            path: '/tool/naire/completedCadets',
                            query: { naire_id: row.naire_id },
                          })
                        "
                      >
                        详情
                      </span>

                      <span class="space"></span>

                      <span
                        class="text"
                        v-if="row.all_number == 0"
                        @click="
                          $router.push({
                            path: '/tool/naire/newlyBuild1',
                            query: { naire_id: row.naire_id },
                          })
                        "
                      >
                        编辑
                      </span>

                      <span
                        class="text"
                        v-if="row.all_number > 0"
                        @click="edit(row.naire_id, row.naire_id)"
                      >
                        编辑
                      </span>

                      <span class="space"></span>

                      <span class="text" v-if="row.type == 2" @click="share(row)">分享</span>

                      <span class="space"  v-if="row.type == 2"></span>

                      <el-dropdown>
                        <span class="text">更多</span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item
                            @click.native="
                              exportTable(row.naire_id, row.all_number)
                            "
                          >
                            导出
                          </el-dropdown-item>

                          <el-dropdown-item
                            @click.native="stopt(row.$index, row.naire_id)"
                            v-if="row.naire_status == 1"
                          >
                            停用
                          </el-dropdown-item>

                          <el-dropdown-item
                            @click.native="open2(row.$index, row.naire_id)"
                            v-if="
                              row.naire_status == 0 && row.naire_position == 1
                            "
                          >
                            启用
                          </el-dropdown-item>

                          <el-dropdown-item
                            @click.native="open(row.$index, row.naire_id)"
                            v-else-if="
                              row.naire_status == 0 &&
                              Number(row.naire_position) > 1
                            "
                          >
                            启用
                          </el-dropdown-item>

                          <el-dropdown-item
                            @click.native="del($index, row.naire_id)"
                          >
                            删除
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </pagination2>
        </div>
      </div>

      <el-dialog
        title="分享"
        :visible.sync="dialogVisible"
        width="422px"
        :close-on-click-modal="false"
      >
        <span style="line-height: 22px">
          《{{ selectRow.naire_name }}》{{
            selectRow.description
          }}，请使用手机微信打开
          {{ selectRow.url }}
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button
            @click="dialogVisible = false"
            style="width: 116px"
            size="medium"
          >
            关闭
          </el-button>
          <el-button
            type="primary"
            size="medium"
            class="cp for-copy"
            :data-clipboard-text="`《${selectRow.naire_name}》${selectRow.description}，请使用手机微信打开  ${selectRow.url}`"
          >
            复制文案+链接
          </el-button>
        </span>
      </el-dialog>
    </template>
    <router-view />
  </div>
</template>

<script>
/**
 * @description: 信息采集
 * @author: 陈莹莹
 * @update: 陈莹莹(2020-11-18 17：29)
 */
import bg from '@/mixin/background'
import ClipboardJS from 'clipboard'
export default {
  name: 'displaySurveyList',

  mixins: [bg],
  data() {
    return {
      selectRow: {},

      dialogVisible: false,

      options: [
        {
          value: '0',
          label: '全部状态',
        },
        {
          value: '1',
          label: '已启用',
        },
        {
          value: '2',
          label: '已停用',
        },
      ],

      options2: [
        {
          value: '0',
          label: '全部方式',
        },
        {
          value: '1',
          label: '行为触发采集',
        },
        {
          value: '2',
          label: '链接分享采集',
        },
      ],

      post: {
        naire_status: '0',
        naire_name: '',
        type: '0',
      },

      total: '', //总数据条数

      pageNum: '',

      // 显示加载图标
      isLoading: false,

      // 没有数据显示
      isNoData: false,

      key: '',
    }
  },

  components: {
    // loading,
    // empty
    // pagination,
  },

  created() {
    this.initCopy()
  },

  filters: {
    getmodefl(val) {
      let text
      switch (Number(val)) {
        case 1:
          text = '行为触发采集'

          break

        default:
          text = '链接分享采集'
          break
      }
      return text
    },
  },

  methods: {
    complete(val) {
      this.total = val.count
    },

    share(val) {
      this.dialogVisible = true
      this.selectRow = val
    },

    initCopy() {
      const self = this
      let clipboard = new ClipboardJS('.for-copy')
      clipboard.on('success', function () {
        self.$root.prompt({
          msg: '复制成功',
          type: 'success',
        })
      })
      clipboard.on('error', function () {
        self.$root.prompt('复制失败')
      })
    },

    //编辑提示
    edit(val) {
      this.$confirm(
        '此信息采集已有学员填写，编辑信息采集的内容可能会导致与已填写的内容有出入；建议只增加新题或修改错别字，避免删除题目和修改题目',
        '编辑信息采集',
        {
          confirmButtonText: '继续编辑',
          cancelButtonText: '取消',
        }
      ).then(() => {
        this.$router.push({
          path: '/tool/naire/newlyBuild1',
          query: { naire_id: val },
        })
      })
    },

    exportTable(val, val2) {
      if (val2 > 0) {
        this.$confirm(
          '确定要导出当前<strong>' +
            val2 +
            '</strong>条数据？当信息采集的题目被编辑后，只导出最新的题目！为避免导出内容有出入，建议避免删除和修改题目内容',
          '导出提示',
          {
            dangerouslyUseHTMLString: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }
        ).then(() => {
          this.$store.dispatch(
            'open',
            `/PcSurvey/exportExecelNew?naire_id=${val}`
          )
        })
      } else {
        this.$root.prompt('暂无数据，无法导出！')
      }
    },

    //删除订单
    del(index, val1) {
      this.$confirm(
        '删除后，已填学员的信息将同时被删除，无法撤销！如需删除，建议先导出内容！',
        '删除信息采集',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
        }
      ).then(() => {
        this.$http({
          url: '/pcSurvey/del',
          data: {
            naire_id: val1,
          },
          callback: () => {
            this.$refs.childDialogBox.reset()
            this.$root.prompt({
              msg: '删除成功',
              type: 'success',
            })
          },
          error: () => {
            this.$root.prompt('删除失败')
          },
        })
      })
    },

    //停用
    stopt(index, val1) {
      this.$confirm(
        '停用后，后访问的学员无法填写信息采集，已填写会保留，是否停用？',
        '停用信息采集',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
        }
      ).then(() => {
        this.$http({
          url: '/PcSurvey/openAndClose',
          data: {
            naire_id: val1,
            naire_status: 0,
          },
          callback: () => {
            this.$root.prompt({
              msg: '停用成功',
              type: 'success',
            })

            this.$refs.childDialogBox.reset()
          },
          error: () => {
            this.$root.prompt('停用失败')
          },
        })
      })
    },

    //启用
    open2(index, val1) {
      this.$confirm(
        '登录后的行为只允许同时弹出一份信息采集，启用当前采集后，其他同类型的采集会自动变为停用状态！',
        '启用信息采集',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
        }
      ).then(() => {
        this.$http({
          url: '/PcSurvey/openAndClose',
          data: {
            naire_id: val1,
            naire_status: 1,
          },
          callback: () => {
            this.$root.prompt({
              msg: '启用成功',
              type: 'success',
            })
            this.$refs.childDialogBox.reset()
          },
          error: () => {
            this.$root.prompt('启用失败')
          },
        })
      })
    },

    //启用
    open(index, val1) {
      this.$http({
        url: '/PcSurvey/openAndClose',
        data: {
          naire_id: val1,
          naire_status: 1,
        },
        callback: () => {
          this.$root.prompt({
            msg: '启用成功',
            type: 'success',
          })
          this.$refs.childDialogBox.reset()
        },
        error: () => {
          this.$root.prompt('启用失败')
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#displaySurveyList {
  min-width: 881px;
  padding: 20px;

  .topinfo {
    padding: 20px;
    font-size: 13px;
    color: #333333;
    line-height: 20px;
    background-color: #fff;
    margin-bottom: 20px;
  }

  .btns {
    @extend %btns;
  }

  .table-contain {
    padding: 0 20px;
    box-sizing: border-box;
  }

  .cover {
    width: 68px;
    height: 38px;
    position: relative;

    .img {
      width: 100%;
      height: 100%;
    }
  }

  .head-right {
    height: 40px;
    float: right;
  }

  .course-info {
    display: flex;
    align-items: center;

    .cover {
      width: 68px;
      height: 38px;
      position: relative;

      .img {
        width: 100%;
        height: 100%;
      }
    }

    .right {
      flex: 1;
      overflow: hidden;
      margin-left: 8px;

      .title {
        font-size: 13px;
        font-weight: 500;
        line-height: 17px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: rgba(51, 51, 51, 1);
      }
    }
  }

  .pagination-wrap {
    padding: 30px 0;
    text-align: center;
  }

  .all {
    padding-top: 20px;
    background: rgba(255, 255, 255, 1);
  }

  .nn {
    display: none;
  }

  .zi1 {
    cursor: pointer;
    float: left;
    text-align: center;
    padding-top: 10px;
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
    line-height: 19px;
    box-sizing: border-box;
    width: 100px;
    height: 36px;
    background: rgba(10, 162, 155, 1);
    border-radius: 4px;
  }

  .order-contain {
    padding: 20px 20px 0px 20px;
  }

  .zi2,
  .zi3 {
    width: 52px;
    height: 17px;
    font-size: 13px;
    color: rgba(51, 51, 51, 1);
    line-height: 17px;
    margin-top: 9px;
    float: left;
  }

  .zi3 {
    margin-left: 30px;
  }

  .el-input__inner {
    height: 36px;
  }

  .list2 .el-select {
    margin-right: 10px;
    width: 140px;
    float: left;
  }

  .el-input {
    float: left;
    width: 188px;
    height: 36px;
    border-radius: 0px 4px 4px 0px;
    border: 1px solid rgba(221, 221, 221, 1);
  }

  .list2 {
    float: right;
  }

  .clear {
    clear: both;
    margin-bottom: 30px;
  }

  table {
    margin-top: 30px;
    margin-left: 20px;
    width: 1220px;
    border-collapse: collapse;
  }

  table .head {
    width: 1080px;
    height: 42px;
    background: rgba(245, 245, 245, 1);
    border-radius: 10px;
    font-size: 14px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    line-height: 19px;
  }

  .tu1 {
    width: 68px;
    height: 38px;
    background: rgba(110, 110, 110, 1);
    margin-top: 22px;
    margin-left: 18px;
    float: left;
  }

  .zi4 {
    width: 135px;
    height: 38px;
    font-size: 14px;
    color: rgba(51, 51, 51, 1);
    line-height: 19px;
    float: left;
    margin-top: 20px;
    margin-left: 10px;
  }

  .zi5 {
    width: 130px;
    height: 19px;
    font-size: 14px;
    color: rgba(51, 51, 51, 1);
    line-height: 19px;
  }

  .zi20 {
    width: 72px;
    height: 17px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(255, 53, 53, 1);
    line-height: 17px;
  }

  .tu2 {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background-color: grey;
    float: left;
  }

  .zi6 {
    height: 17px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
    line-height: 17px;
    float: left;
    margin-top: 2px;
    margin-left: 10px;
  }

  .zi7 {
    width: 165px;
    height: 19px;
    font-size: 14px;
    color: rgba(51, 51, 51, 1);
    line-height: 19px;
  }

  .zi8 {
    width: 56px;
    height: 19px;
    font-size: 14px;
    color: rgba(51, 51, 51, 1);
    line-height: 19px;
  }

  .zi9 {
    width: 77px;
    font-size: 14px;
    color: rgba(27, 157, 151, 1);
    line-height: 19px;
  }

  .zi9 span {
    cursor: pointer;
  }

  .el-pagination {
    margin-top: 31px;
    margin-bottom: 46px;
  }
}
</style>
